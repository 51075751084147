<template>
  <div class="services-container" v-if="$store.getters.serviceType">
    <router-view :key="Math.random()" />
  </div>
</template>

<script>
import { onMounted, watch } from '@vue/composition-api';
import store from '../../store';

export default {
  setup(props) {
    onMounted(() => {
      store.commit('setServiceType', props.type);
    });

    watch(
      () => props.type,
      () => {
        store.commit('setServiceType', props.type);
      }
    );

    return {};
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.services-container {
  padding: 1.5rem 2rem;
  height: calc(100vh - 110px);
  overflow: auto;
}
</style>
